<template>
  <div>
    <div
      class="modal fade"
      id="locationModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Select Location
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <select
                class="form-select"
                aria-label="Select default location"
                v-model="selectedLocation"
              >
                <option
                  v-for="location in locations"
                  :key="'l' + location.id"
                  :selected="location.isDefault"
                  :value="location"
                >
                  {{ location.location }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="saveLocation">
              Save
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              @click="toggleModal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <main class="form-signin mt-5">
      <form @submit.prevent="submit">
        <img class="mb-4" src="@/assets/logo.png" style="height: 7.5rem" />
        <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder=""
            v-model="form.username"
          />
          <label for="floatingInput">Username</label>
        </div>
        <div class="form-floating">
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            placeholder="Password"
            v-model="form.password"
          />
          <label for="floatingPassword">Password</label>
        </div>

        <!-- <div class="checkbox mb-3">
          <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label>
        </div> -->
        <button class="w-100 btn btn-lg btn-primary" type="submit">
          Sign in
        </button>
        <div>
          <router-link to="/account/pwdreset" class="text-muted"
            >Forgot Password</router-link
          >
        </div>
        <p class="mt-5 mb-3 text-muted">
          <!-- // TODO: Need to acquire version from package.json -->
          {{ footerYear }} HealthCore Technology, LLC v1.0.3
        </p>
      </form>
    </main>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'
import { ref } from '@vue/reactivity'
// eslint-disable-next-line no-unused-vars
import * as bootstrap from '../../node_modules/bootstrap/dist/js/bootstrap.esm.min.js'
import { onMounted } from 'vue'
export default {
  setup () {
    const footerYear = new Date().getFullYear()
    // Use router
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const form = ref({
      username: '',
      password: ''
    })
    const token = ref(null)
    const userData = ref(null)

    const locationModal = ref({})
    const toggleModal = () => {
      locationModal.value.toggle()
    }
    const locations = ref([])
    const selectedLocation = ref(null)
    const saveLocation = async () => {
      if (!selectedLocation.value) {
        alert('Please select a location!')
        return
      }

      locationModal.value.hide()
      store.dispatch('setUserCurrentLocation', selectedLocation)
      store.dispatch('setUserIsLogin', true)
      store.dispatch('setUserToken', token.value)
      store.dispatch('setUserInfo', userData.value)
      // console.log('route>', route)
      if (route.query.id) {
        console.log('Home2')
        router.push({ name: 'Home2', params: { id: route.query.id } })
      } else {
        router.push({ path: '/' })
      }
    }

    const submit = async () => {
      // TODO: Refactor this area
      const result = await axios.post(
        process.env.VUE_APP_API_URL + '/patientportal/authenticate',
        {
          username: process.env.VUE_APP_USERNAME,
          password: process.env.VUE_APP_KEY
        }
      )

      const userResult = await axios.post(
        process.env.VUE_APP_API_URL + '/users/authenticate',
        form.value,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'bearer ' + result.data.token
          }
        }
      )

      if (userResult.data.userId !== 0) {
        // const locationListResult = await axios.get(
        //   process.env.VUE_APP_API_URL +
        //     `/users/${userResult.data.userId}/locations`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${userResult.data.token}`
        //     }
        //   }
        // )
        // store.dispatch('setUserLocations', locationListResult.data)
        // locations.value = locationListResult.data
        // if (locationListResult.data.length > 0) {
        //   locationModal.value.show()
        //   token.value = userResult.data.token
        //   userData.value = userResult.data
        //   selectedLocation.value = locations.value.find(el => el.isDefault)
        //   return
        // }

        store.dispatch('setUserIsLogin', true)
        store.dispatch('setUserToken', userResult.data.token)
        store.dispatch('setUserInfo', userResult.data)

        // console.log('route>', route)
        if (route.query.id) {
          // console.log('Home2')
          router.push({ name: 'Home2', params: { id: route.query.id } })
        } else {
          router.push({ path: '/' })
        }
      } else {
        alert(userResult.data.msg)
      }
    }

    onMounted(async () => {
      const modalDom = document.getElementById('locationModal')
      locationModal.value = new bootstrap.Modal(modalDom)
    })

    return {
      form,
      submit,
      locations,
      toggleModal,
      saveLocation,
      selectedLocation,
      footerYear
    }
  }
}
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
